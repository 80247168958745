.heroPic{
    width: 100%;
    height: 800px;
    object-fit: cover;
    filter: grayscale(100%);
}

.heroText {
    font-size: 2rem;
    line-height: 2.3rem;
    font-family: 'Indie Flower', cursive;

}
.heroTextDiv{
    position: absolute;
    top: 232px;
    right: 240px;
    width: 330px;

}

.heroDiv{
    position: relative;
}
@media (max-width:1000px){
    .heroText{
        font-size: 2rem;
    }
    .heroTextDiv{
        right: 70px;
        width: 330px;
        top: 100px;

    }
    .heroPic{
        height: 500px;
    }
}


@media (max-width:700px) {
.heroText{
    font-size: 1.2rem;
    line-height: 1.5rem;
}
.heroTextDiv{
    top: 60px;
    right: 19px;
    width: 169px;

}
.heroPic{
    height: 300px;
}
}
.Linkclass{
    color: black;
    margin: auto;
}
.arrowToAbout{
    font-size: 2rem;
    display: block;
    margin: auto;
    text-align: center;

    transition:font-size 0.5s;
}
.arrowToAbout:hover{
    font-size: 3rem;
}
