.Header{
    display: flex;
    max-width: 1080px;
    justify-content: space-between;
    padding: 10px;
    margin: auto;

}
.menu > a{
    margin: auto 20px;
    text-align: center;
    text-decoration: none;
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.menu{
    display: flex;
    justify-content: flex-end;
    text-align: right;
}
.h1{
    font-family: 'indie flower', cursive;
}
.burger{
    display: none;
}
.hamburgerMenu{
    display: grid;
    grid-template-columns: 1fr;
    position: absolute;
    top: 60px;
    right: 29px;
    background-color: white;
    z-index: 1;
}
.hamburgerMenu > a {
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin: 5px;
text-decoration: none;
}

@media (max-width:800px) {
    .menu{
        display: none;
    }
    .burger{
        display: block;
        margin: auto 0px;
        font-size: 2rem;
        
    }
}